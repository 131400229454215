<template>
    <!-- login-area -->
    <section class="login-area">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="login-right row">
              <div class="login-form custom-form col-lg-6 col-md-8 mx-auto">
                <!-- logo -->
                <div
                  class="logo-login wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="1s"
                >
                  <a href="index.html"
                    ><img
                        width="245px"
                      class="img-fulid"
                      src="../assets/img/logo.svg"
                      alt="logo"
                  /></a>
                </div>
                <!-- logo -->
                  <div
                    class="login-title wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                  >
                    <h2>Two Factor Authentication</h2>
                  </div>
                  <p v-if="$route.query.message" class="alert alert-success">
                    {{ message }}
                  </p>
                  <p></p>
                  <div class="row">
                    <div class="col-lg-10 col-md-12 col-sm-12 col-xs-12 mx-auto">
                        <form
                          class="wow fadeInRight login_"
                          data-wow-duration="1s"
                          data-wow-delay="1s"
                          v-on:submit.prevent="loginProcess"
                        >
                          <div class="login-error-message text-center" v-if="authError">
                            The provided code is not valid
                          </div>
                          <div
                            class="form-group label-design"
                            v-on:click="inputLabelStyle($event)"
                          >
                            <label
                              class="label-class label-top"
                              for="inlineFormInputGroup"
                              >OTP:</label
                            >
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <div class="input-group-text">
                                  <img
                                    class="img-fluid"
                                    src="../assets/img/lock.svg"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <input
                                type="text"
                                class="form-control"
                                @focus="inputLabelStyle($event, 'parent')"
                                id="inlineFormInputGroup"
                                placeholder=""
                                v-model="otp_.code"
                              />
                            </div>
                          </div>
                          <div
                            class="validate-error-message"
                            v-if="$vd.otp_.email.$hasError === true"
                          >
                            {{ $vd.otp_.$errors.email[0] }}
                          </div>
          
                          <button type="submit" class="login-btn" :disabled="btnLoader">
                            <span v-if="btnLoader"
                              ><span class="spinner-grow spinner-grow-sm"></span>
                              Loading...</span
                            >
                            <span v-if="!btnLoader">Login</span>
                          </button>
                          <!-- <p class="allready">You don’t have a crm? <a href="#">Signup</a></p> -->
                        </form>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- login-area -->
  </template>
  
  <script>
  import $ from "jquery";
  import VueDaval from "vue-daval";
  import axios from "axios";
  import firebase from "firebase";
  export default {
    name: "Login",
    beforeCreate: function () {
      var auth = this.$storage.get("auth");
      if (auth) {
        this.$router.push("/dashboard");
      }
    },
    mixins: [VueDaval],
    data() {
      return {
        otp_ : { code : ""},
        authError: false,
        btnLoader: false,
        message: "",
      };
    },
    vdRules: {
      otp_:{
        code: {required : true}
      }
    },
    created() {
      setTimeout(() => {
        if ($("#inlineFormInputGroup").is(":-webkit-autofill")) {
          $(".label-design").addClass("input-border");
          $(".label-design label").addClass("label-top");
        }
      }, 1000);
    },
    methods: {
      subscribeTokenToTopic(token, topic) {
        fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
          method: "POST",
          headers: new Headers({
            Authorization: `key=AAAAG69RFgo:APA91bFImVgPFU7wMbLfrI4cUU_TPFlkJQgn4MLrKxbAaEHMH-LO0HwECVaFvr1ApLXb7-3CNaGaQetr0fQ6_W7YOwAzwL8-DFCFYf6oxTf8hmEVZ16jmPN2KzrZ5eyKG3IB_zfnmpyL`,
          }),
        })
          .then((response) => {
            if (response.status < 200 || response.status >= 400) {
              console.log(response.status, response);
            }
  
            console.log(`"${topic}" is subscribed`);
          })
          .catch((error) => {
            console.error(error.result);
          });
        return true;
      },
      unsubscribeTokenToTopic(token, topic) {
        fetch(`https://iid.googleapis.com/iid/v1:batchRemove`, {
          method: "POST",
          headers: new Headers({
            Authorization: `key=AAAAG69RFgo:APA91bFImVgPFU7wMbLfrI4cUU_TPFlkJQgn4MLrKxbAaEHMH-LO0HwECVaFvr1ApLXb7-3CNaGaQetr0fQ6_W7YOwAzwL8-DFCFYf6oxTf8hmEVZ16jmPN2KzrZ5eyKG3IB_zfnmpyL`,
          }),
          body: JSON.stringify({
            to: "/topics/" + topic,
            registration_tokens: [token],
          }),
        })
          .then((response) => {
            if (response.status < 200 || response.status >= 400) {
              console.log(response.status, response);
            }
  
            console.log(`"${topic}" is unsubscribed`);
          })
          .catch((error) => {
            console.error(error.result);
          });
        return true;
      },
      async registerServiceWorker(){
      // Check if the browser supports service workers
      // if ('serviceWorker' in navigator) {
      //   const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      //   console.log('Service worker registered:', registration);
      // }
    },
      loginProcess() {
        let otp_session = this.$storage.get("session_otp");
        axios.defaults.headers.common["Authorization"] = otp_session.token;
        this.$vd.otp_
          .$validate()
          .then(() => {
            this.btnLoader = true;
            axios
              .post(`user/fa/otp/session/${otp_session.user.id}/verify`, this.otp_)
              .then((res) => {
                this.authError = false;
                if (res.data.success) {  
                    this.$storage.set("auth", otp_session);
                    if (otp_session.user.user_type == 0) {
                      this.registerServiceWorker();
                        this.$router.push("/admin/dealers");
                    } else {
                        let v = this;
                        this.registerServiceWorker();
                        this.$router.push("/dashboard");
                        const messaging = firebase.messaging();
                        messaging
                        .requestPermission()
                        .then(function () {
                            return messaging.getToken();
                        })
                        .then(function (token) {
                            console.log(token);
                        
                            if (token) {  
                            if (res.data.noti) {
                                if (res.data.noti.facebook == 1) {
                                v.subscribeTokenToTopic(
                                    token,
                                    "facebook" +
                                    "_" +
                                    v.$storage.get("auth").user.id +
                                    "_" +
                                    v.$storage.get("auth").user.dealer_id
                                );
                                }
                                if (res.data.noti.sms == 1) {
                                v.subscribeTokenToTopic(
                                    token,
                                    "sms" +
                                    "_" +
                                    v.$storage.get("auth").user.id +
                                    "_" +
                                    v.$storage.get("auth").user.dealer_id
                                );
                                }
                                if (res.data.noti.email == 1) {
                                v.subscribeTokenToTopic(
                                    token,
                                    "email" +
                                    "_" +
                                    v.$storage.get("auth").user.id +
                                    "_" +
                                    v.$storage.get("auth").user.dealer_id
                                );
                                }
                                if (res.data.noti.NewLead == 1) {
                                v.subscribeTokenToTopic(
                                    token,
                                    "NewLead" +
                                    "_" +
                                    v.$storage.get("auth").user.id +
                                    "_" +
                                    v.$storage.get("auth").user.dealer_id
                                );
                                }
                                if (res.data.noti.alert == 1) {
                                v.subscribeTokenToTopic(
                                    token,
                                    "alert" +
                                    "_" +
                                    v.$storage.get("auth").user.id +
                                    "_" +
                                    v.$storage.get("auth").user.dealer_id
                                );
                                }
                                if (res.data.noti.automatedtasks == 1) {
                                v.subscribeTokenToTopic(
                                    token,
                                    "automatedtasks" +
                                    "_" +
                                    v.$storage.get("auth").user.id +
                                    "_" +
                                    v.$storage.get("auth").user.dealer_id
                                );
                                }
                                if (res.data.noti.leadassign == 1) {
                                v.subscribeTokenToTopic(
                                    token,
                                    "leadassign" +
                                    "_" +
                                    v.$storage.get("auth").user.id +
                                    "_" +
                                    v.$storage.get("auth").user.dealer_id
                                );
                                }
                                if (res.data.noti.innercircle == 1) {
                                v.subscribeTokenToTopic(
                                    token,
                                    "innercircle" +
                                    "_" +
                                    v.$storage.get("auth").user.id +
                                    "_" +
                                    v.$storage.get("auth").user.dealer_id
                                );
                                }
                            }
                            }
                        })
                        .catch(function (err) {
                            console.log("User Chat Token Error" + err);
                        });

                    
                    }
                } else {
                  this.btnLoader = false;
                }
              })
              .catch((err) => {
                this.authError = true;
                this.btnLoader = false;
                //setTimeout(() => this.authError=false, 5000);
                console.log(err);
              });
          })
          .catch(() => {});
      },
      inputLabelStyle(event, type = null) {
        if (type == "parent") {
          $(event.currentTarget).parent().parent().addClass("input-border");
          $(
            event.currentTarget.parentElement.parentElement.querySelector("label")
          ).addClass("label-top");
        } else {
          $(event.currentTarget).addClass("input-border");
          $(event.currentTarget.querySelector("label")).addClass("label-top");
        }
      },
    },
    mounted() {
      if (this.$route.query.message) {
        this.message = this.$route.query.message;
      }
    },
  };
  </script>